<template>
  <vx-card style="padding-left: 10px">
    <!-- <vs-label style="font-weight: bold"> -->

    <!-- </vs-label> -->
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
      >
        <template slot="header">
          <vx-tooltip text="Create new users">
            <vs-button class="mb-4 md:mb-0" @click="addDetailHandler()"
              >Add User</vs-button
            >
          </vx-tooltip>
        </template>
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          ></div>
          <v-select
            v-model="dataTableParams.limit"
            class="mr-4"
            :options="limitOptions"
            :clearable="false"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="firstName" >User</vs-th>
          <vs-th sort-key="email" >Email</vs-th>
          <vs-th sort-key="phoneNumber" >Contact Number</vs-th>
          <vs-th sort-key="accountStatus" >Status</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th sort-key="action" >Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr]._id">
              <div class="flex items-center">
                <vs-avatar
                  class="m-0"
                  :src="data[indextr].profileImage"
                  size="35px"
                  @click="
                    $router.push({
                      path: `user-management/view/${data[indextr]._id}`,
                    })
                  "
                />
                <div
                  class="leading-tight ml-3"
                  @click="
                    $router.push({
                      path: `user-management/view/${data[indextr]._id}`,
                    })
                  "
                >
                  <p class="feed-author font-semibold">
                    {{
                      data[indextr].firstName !== null
                        ? data[indextr].firstName + " " + data[indextr].lastName
                        : "N/A"
                    }}
                  </p>
                  <span class="flex items-center"
                    ><small>{{
                      data[indextr].username === ""
                        ? ""
                        : data[indextr].username
                    }}</small></span
                  >
                </div>
              </div>
            </vs-td>
            <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>
            <vs-td
              :data="data[indextr].phoneNumber"
              style="white-space: pre-wrap"
              >{{ data[indextr].phoneNumberCountryCode }} {{ data[indextr].phoneNumber }}</vs-td
            >
            <!-- <vs-td :data="data[indextr].lastName">{{ data[indextr].lastName }}</vs-td> -->

            <vs-td :data="data[indextr].accountStatus">{{
              data[indextr].accountStatus === 'Deleted' ?
                'In-Active' :
                data[indextr].accountStatus
            }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-row vs-type="flex">
                <vx-tooltip text="Edit" position="top" delay=".3s">
                  <feather-icon
                    icon="Edit3Icon"
                    svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                    @click="editDetailHandler(data[indextr]._id)"
                  />
                </vx-tooltip>
                <vx-tooltip v-if="data[indextr].accountStatus=='Active'" text="Deactivate" position="top" delay=".3s">
                  <feather-icon
                    icon="SlashIcon"
                    svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                    @click="deleteDetailHandler(data[indextr], indextr)"
                  />
                </vx-tooltip>
              </vs-row>
              <!-- <vs-row vs-type="flex" vs-justify="space-between">
                <vx-tooltip text="Edit" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    icon="icon-edit"
                    @click="editDetailHandler(data[indextr]._id)"
                    color="warning"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="View" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="viewDetailHandler(data[indextr]._id)"
                    icon="icon-user"
                    color="primary"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Delete" position="top" delay=".3s">
                  <vs-button
                    type="gradient"
                    size="small"
                    icon-pack="feather"
                    @click="deleteDetailHandler(data[indextr],indextr)"
                    icon="icon-trash"
                    color="danger"
                  ></vs-button>
                </vx-tooltip>
              </vs-row>-->
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <span class="mr-2"
        >{{
          dataTableParams.page * dataTableParams.limit -
          (dataTableParams.limit - 1)
        }}
        -
        {{
          pagination.total - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : pagination.total
        }}
        of {{ pagination.total }}</span
      >
      <!--<vs-pagination
        :total="pagination.totalPage"
        v-model="dataTableParams.page"
      ></vs-pagination>-->
      <div
        class="vs-row"
        style="justify-content: space-between; display: flex; width: 100%"
      >
      <div class="vs-col vs-pagination--mb vs-xs-12 vs-sm-12 vs-lg-6" style="justify-content: flex-start; display: flex; align-items: center; margin-left: 0%; width: 100%;"></div>
        <paginate
          :page-count="pagination.totalPage"
          :click-handler="handleChangePageVuPage"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </vx-card>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
import { userMixin } from "../../mixins/userMixin";
import Paginate from "vuejs-paginate";
Vue.use(Paginate);

export default {
  components: {
    "v-select": vSelect,
  },
  mixins: [userMixin],
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 30,
      },
      users: [],
      pagination: {
        total: 0,
        totalPage: 1,
        page: 1,
      },
      limitOptions: [30, 50, 100, 150],
      selectedUser: {},
      selectedIndex: 0,
      headerTitle: ["User", "Email", "Contact Number", "Status", "Created At"],
      headerVal: [],
    };
  },
  methods: {
    //...mapActions("user", ["fetchUsers", "removeUser", "fetchUserForCsv"]),
    moment(date) {
      return moment(date);
    },
    getUsersList() {
      let self = this;
      this.getUsers(self.dataTableParams).then((res) => {
        self.users = res.data;
        self.pagination = res.pagination;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getUsersList();
    },
    handleChangePageVuPage(page) {
      this.dataTableParams.page = page;
      this.getUsersList();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getUsersList();
    },
    editDetailHandler(id) {
      this.$router.push({ name: "EditUserDetail", params: { id: id } });
    },
    viewDetailHandler(id) {
      this.$router.push({ name: "UserDetail", params: { id: id } });
    },
    addDetailHandler() {
      this.$router.push({ name: "AddUser" });
    },
    deleteDetailHandler(user, i) {
      this.selectedUser = user;
      this.selectedIndex = i;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Deactivate`,
        text: `Are you sure you want to deactivate account of ${ user.firstName ? user.firstName + " " + user.lastName : "Kulcha User" }?`,
        accept: this.confirmDeleteRecord,
        acceptText: "Deactivate",
      });
    },
    confirmDeleteRecord() {
      this.removeUser(this.selectedUser._id).then((res) => {
        this.$vs.notify({
          title: "User Deactivated",
          text: '',
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.users[this.selectedIndex].accountStatus='In-Active';
        //this.totalDocs -= 1;
      });
    },

    exportUserCsv() {
      this.fetchUserForCsv()
        .then((res) => {
          import("@/vendor/Export2Excel").then((excel) => {
            const list = res.data.data;
            const data = this.formatJson(this.headerVal, list);
            excel.export_json_to_excel({
              header: this.headerTitle,
              data,
              filename: "exportUser",
              autoWidth: true,
              bookType: "csv",
            });
          });
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  computed: {
    totalPage: function () {
      return this.pagination.totalPage;
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getUsersList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getUsersList();
      }
    },
    watch:{
      $route (to, from){
        this.getUsersList();
      }
    }
  },
  created() {
    this.getUsersList();
  },
};
</script>
